<template>
  <div>
    <v-card width="100%" elevation="3" class="px-9 pt-10 pb-14 mb-12" v-if="site_config.documents_page">
      <v-card-title style="font-size: 2rem; line-height: 1.4rem; color: #616161" class="py-4">
        {{ site_config.documents_page.title }}
      </v-card-title>
      <v-card-text class="pt-3 pb-2" style="font-size: 1.2rem; line-height: 1.8rem; width: 80%" v-if="site_config.documents_page.text">
        {{ site_config.documents_page.text }}
      </v-card-text>
    </v-card>

    <v-row>
      <v-col cols="12">
        <OrganizationDocuments :site="true"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {check_show_page, ext_obj} from "@/utils/icons";
import {BASE_URL_AXIOS} from "@/utils/axios";
import {mapGetters} from "vuex";


export default {
  name: "Documents",
  components: {
    OrganizationDocuments: () => import("@/components/OrganizationDocumentsNew")
  },
  computed: {
    ...mapGetters({
      site_config: 'getSiteConfig'
    }),
    accent_color() {
      return this.site_config.site_color || 'secondary'
    }
  },
  data() {
    return {
      documents: [
        {
          name: 'Установчі документи',
          items: [
            { name: 'Статут підприємства', filename: 'statut_2021.pdf' },
            { name: 'Протокол установчих зборів', filename: 'protocol_first.xlsx' },
            { name: 'Рішення зборів від 22.04.2020', filename: 'рішення_22_04_2020.xlsx' },
          ]
        }
      ],
      items: [],
    }
  },
  methods: {
    getFileIcon(ext) {
      const file_ext = (ext_obj.find(item_ext => item_ext.ext === ext)) || {ext: 'txt', mime: ''}
      return file_ext.icon || 'mdi-document'
    },
    getFile(payload) {
      return `${BASE_URL_AXIOS}/${payload.file_path}`
    }
  },
  created() {
    const check = check_show_page(this.$route.name, this.site_config)
    if (!check) {
      this.$router.push({ name: 'SiteBlocked' })
    }

    // const organization_slug = this.$route.params.organization || ''
    // if (organization_slug) {
    //   siteAPI.get_files(organization_slug)
    //       .then(response => response.data)
    //       .then(data => {
    //         this.items = data
    //       })
    //       .catch(() => {
    //         this.$router.push({ name: 'NotFoundSite' })
    //       })
    // }
  }
}
</script>

<style scoped lang="scss">
.contact-wrapper {
  margin-bottom: 9px;
  padding: 12px;

  .contact-name {
    font-size: 1.06rem;
    text-transform: uppercase;
    font-weight: 500
  }

  .contact-value {
    .contact-value-text {
      font-size: 1.1rem
    }
  }
}

.panel {
  :deep(.v-expansion-panel-content .v-expansion-panel-content__wrap) {
    padding-bottom: 0 !important;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 0;
  }
}
</style>